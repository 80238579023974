import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import NoContent from "../../Assessts/Images/NoContent.png";
import classes from "./Info.module.css";

import Header from "../../reusableComponents/Header";

import { storeActions } from "../../Store/StoreDetails/StoreSlice";
import { getStoreByIdHandle } from "../../Store/StoreDetails/StoreActions";
import { toast } from "react-toastify";

const Info = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [storeData, setStoreData] = useState();
  const B2BauthToken = localStorage.getItem("B2BAuthToken");

  useEffect(() => {
    dispatch(storeActions.setStoreIdHandler(id));

    if (B2BauthToken) {
      dispatch(getStoreByIdHandle(B2BauthToken, id)).then((result) => {
        if (result.status === "success") {
          setStoreData(result.data);
        }else  if(result.status==="error")
          {
            toast.error("Something is Wrong, Please try again later.",{
              autoClose:false
            })
          } 
      });
    }
  }, [B2BauthToken, dispatch, id]);

  const getContentName = (pageName) => {
    if (!storeData) return "";

    if (pageName === "Privacy Policy") {
      return storeData.PrivacyPolicy;
    } else if (pageName === "Return Policy") {
      return storeData.ReturnPolicy;
    } else {
      return storeData.ShippingPolicy;
    }
  };

  const content = getContentName(location.state.name);

  return (
    <div>
      <Header pagename={location.state.name} />
      {storeData &&
        (content ? (
          <div className={`${classes.PrivacyContainer}`}>
            <div
              contentEditable="true"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          </div>
        ) : (
          <div className={`${classes.NoImageContainer}`}>
            <img src={NoContent} alt="Placeholder" />
          </div>
        ))}
    </div>
  );
};

export default Info;
