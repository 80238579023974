import { DateFomatyyyymmdd } from "../../GlobalFunctions/Global";
import { CustomerInstance } from "../../Router/AxiosInstance";
// import { customerActions } from "./CustomerSlice";

export const getCustomerLedger = (
  b2btoken,
  id,
  locationid,
  startDate,
  endDate
) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    const formattedStartDate = DateFomatyyyymmdd(startDate);
    const formattedEndDate = DateFomatyyyymmdd(endDate);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/fetch-customer-LedgerReport/${id}?DateFrom=${formattedStartDate}&ToDate=${formattedEndDate}&LocationID=${locationid}`,
      headers: {
        Authorization: "Bearer " + b2btoken,
      },
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          result.status = "error";
          result.msg = "Server error! Please try again later.";
        } else {
          console.log(error);
        }
      });
    return result;
  };
};

export const getCustomerInvoice = (b2btoken, id, startDate, endDate, locationid) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    const formattedStartDate = DateFomatyyyymmdd(startDate);
    const formattedEndDate = DateFomatyyyymmdd(endDate);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/get-cusomer-invoice/${id}?DateFrom=${formattedStartDate}&ToDate=${formattedEndDate}&LocationID=${locationid}`,
      headers: {
        Authorization: "Bearer " + b2btoken,
      },
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          result.status = "error";
          result.msg = "Server error! Please try again later.";
        } else {
          console.log(error);
        }
      });
    return result;
  };
};

export const getOutstanding = (
  b2btoken,
  id,
  toDate,
  autoAdjust,
  locationid
) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    toDate = toDate ? toDate.replace(/-/g, "/") : "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/customer-outstanding-api?StoreId=${id}&AutoAdjust=${autoAdjust}&ToDate=${toDate}&LocationID=${locationid}`,

      headers: {
        Authorization: "Bearer " + b2btoken,
      },
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          result.status = "error";
          result.msg = "Server error! Please try again later.";
        } else {
          console.log(error);
        }
      });
    return result;
  };
};

export const getInvoicePdf = (id, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/get-invoice-details/${id}`,
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          result.status = "error";
          result.msg = "Server error! Please try again later.";
        } else {
          console.log(error);
        }
      });
    return result;
  };
};

export const getInvoiceCSV = (id, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/get-invoice-CSVFile/${id}`,
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.data;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          result.status = "error";
          result.msg = "Server error! Please try again later.";
        } else {
          console.log(error);
        }
      });
    return result;
  };
};

export const getCustomerCreditList = (b2btoken, id, fromDate, toDate, locationid) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };
    const formattedStartDate = DateFomatyyyymmdd(fromDate);
    const formattedEndDate = DateFomatyyyymmdd(toDate);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/get-credit-note-details/${id}?DateFrom=${formattedStartDate}&ToDate=${formattedEndDate}&LocationID=${locationid}`,
      headers: {
        Authorization: "Bearer " + b2btoken,
      },
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.data.Status === "success") {
          result.status = "success";
          result.data = response.data.data;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          result.status = "error";
          result.msg = "Server error! Please try again later.";
        } else {
          console.log(error);
        }
      });
    return result;
  };
};

export const getCustomerCreditDetails = (id, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/get-credit-note-customer-details/${id}`,
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          result.status = "error";
          result.msg = "Server error! Please try again later.";
        } else {
          console.log(error);
        }
      });
    return result;
  };
};

export const getCustomerDebitList = (b2btoken, id, fromDate, toDate, locationid) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    const formattedStartDate = DateFomatyyyymmdd(fromDate);
    const formattedEndDate = DateFomatyyyymmdd(toDate);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/get-debit-note-list/${id}?DateFrom=${formattedStartDate}&ToDate=${formattedEndDate}&LocationID=${locationid}`,
      headers: {
        Authorization: "Bearer " + b2btoken,
      },
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.data.Data;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          result.status = "error";
          result.msg = "Server error! Please try again later.";
        } else {
          console.log(error);
        }
      });
    return result;
  };
};

export const getCustomerDebitDetails = (id, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/get-debit-note-details/${id}`,
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          result.status = "error";
          result.msg = "Server error! Please try again later.";
        } else {
          console.log(error);
        }
      });
    return result;
  };
};

export const setPendingInvoiceList = (b2btoken, id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/set-invoice-byId/${id}`,
      headers: {
        Authorization: "Bearer " + b2btoken,
      },
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const getCustOMERPendingInvoiceList = (b2btoken, id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/get-receipt-list/${id}`,

      headers: {
        Authorization: "Bearer " + b2btoken,
      },
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const getBankList = (id) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/get-bank-details/${id}`,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

export const updatePEndingInvoices = (id, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/adjust-pending-invoices/${id}`,
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return result;
  };
};

