import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import classes from "./Profile.module.css";
import Header from "../../reusableComponents/Header";
import {
  getProfileHandler,
  updateProfileHandler,
} from "../../Store/Auth/AuthActions";
import { orderListActions } from "../../Store/OrderList/OrderListSlice";
import { storeActions } from "../../Store/StoreDetails/StoreSlice";

const Profile = () => {
  const { id, locationid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const B2BauthToken = localStorage.getItem("B2BAuthToken");
  const [formData, setFormData] = useState({
    CompanyName: "",
    ContactNumber: "",
    Email: "",
    AddressLine1: "",
    Locality: "",
    city: "",
    State: "",
    Pincode: "",
    GST_Number: "",
    PAN_Number: "",
    License: "",
  });

  useEffect(() => {
    dispatch(storeActions.setStoreIdHandler(id));
    dispatch(storeActions.setLocationIdHandler(locationid));
    dispatch(getProfileHandler(B2BauthToken, id)).then((result) => {
      if (result.status === "success") {
        if (result.data.registered === true) {
          const data = result.data.data[0];
          setFormData({
            CompanyName: data.CompanyName || "",
            ContactNumber: data.ContactNumber || "",
            Email: data.Email || "",
            AddressLine1: data.AddressLine1 || "",
            Locality: data.Locality || "",
            city: data.city || "",
            State: data.State || "",
            Pincode: data.Pincode || "",
            GST_Number: data.GST_Number || "",
            PAN_Number: data.PAN_Number || "",
            License: data.License || "",
          });
          dispatch(orderListActions.setProfileData(result.data.data[0]));
        }
      }else if(result.status==="error")
        {
          toast.error("Something is Wrong, Please try again later.",{
            autoClose:false
          })
        }
    });
  }, [dispatch, B2BauthToken, id, locationid]);

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateProfileHandler(B2BauthToken, id, formData)).then(
      (result) => {
        if (result.status === "success") {
          toast.success("Profile Updated Successfully!");
          dispatch(getProfileHandler(B2BauthToken, id)).then((result) => {
            if (result.status === "success") {
              const data = result.data.data[0];
              setFormData({
                CompanyName: data.CompanyName || "",
                ContactNumber: data.ContactNumber || "",
                Email: data.Email || "",
                AddressLine1: data.AddressLine1 || "",
                Locality: data.Locality || "",
                city: data.city || "",
                State: data.State || "",
                Pincode: data.Pincode || "",
                GST_Number: data.GST_Number || "",
                PAN_Number: data.PAN_Number || "",
                License: data.License || "",
              });
              dispatch(orderListActions.setProfileData(result.data.data[0]));
              navigate(-1);
            }else if(result.status==="error")
              {
                toast.error("Something is Wrong, Please try again later.",{
                  autoClose:false
                })
              }
          });
        }
      }
    );
  };

  const generateLabel = (key) => {
    switch (key) {
      case "GST_Number":
        return "GST Number";
      case "PAN_Number":
        return "PAN Number";
      default:
        return key
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
    }
  };

  return (
    <>
      <Header pagename={"Edit Details"} />

      <div className={`${classes.EditDetailWrapper}`}>
        <form onSubmit={handleSubmit} className={classes.FormContainer}>
          {Object.keys(formData).map((key) => (
            <div key={key} className={classes.FormField}>
              <label htmlFor={key}>{generateLabel(key)}</label>
              <input
                type="text"
                id={key}
                name={key}
                value={formData[key]}
                onChange={handleChange}
              />
            </div>
          ))}
          
          <button type="submit" className={classes.SaveButton}>
            Save Details
          </button>
    
        </form>
      </div>
    </>
  );
};

export default Profile;
