import { ProductInstance } from "../../Router/AxiosInstance";
import { productActions } from "./ProductSlice";

export const getProductByStoreHandler = (params) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    if (params.active === "all") {
      params.active = "";
    }
    if (params.MfgGroupName === "Not Listed" || params.MfgGroupName === "all") {
      params.MfgGroupName = "";
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomers/product/getAllProducts?ClientRegID=${params.ClientRegID}&LocationID=${params.LocationID}&IsAllProduct=true&page=${params.page}&limit=${params.limit}&active=${params.active}&search=${params.search}&MfgGroupName=${params.MfgGroupName}`,
    };

    await ProductInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data.data;
          result.totalItems = response.data.totalItems;
          result.totalPages = response.data.totalPages;
          result.totalActiveItems = response.data.totalActiveItems;
          result.totalInActiveItems = response.data.totalInActiveItems
          result.uniqueGroupNames = response.data.uniqueGroupNames;
          result.resultProd = response.data.resultProd;

          dispatch(productActions.setProductListHandler(response.data.data));
        }
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response &&
           error.response.status === 500
        ) {
          result.status = "error";
            result.msg = "Server error! Please try again later.";
        }
      });
    return result;
  };
};

export const getStockDetails = (id, locationid) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomers/product/getStockDetails/${id}/${locationid}`,
    };

    await ProductInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.msg = response.data.msg;
          result.data = response.data.data;
          dispatch(productActions.setStockListHandler(response.data.data))
        }
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response &&
           error.response.status === 500
        ) {
          result.status = "error";
            result.msg = "Server error! Please try again later.";
        }
      });
    return result;
  };
};
