import { CustomerInstance } from "../../Router/AxiosInstance";
import { authActions } from "./AuthSlice";

export const sendOtpHandle = (data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/customer/send-otp",
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          result.status = "error";
          result.msg = "Server error! Please try again later.";
        } else {
          console.log(error);
        }
      });
    return result;
  };
};

export const resendOtpHandle = (data1) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let data = JSON.stringify(data1);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/customer/resend-otp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          result.status = "error";
          result.msg = "Server error! Please try again later.";
        } else {
          console.log(error);
        }
      });
    return result;
  };
};

export const verifyOtpHandle = (data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/b2bCustomer/verify-and-login",
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          dispatch(authActions.setProfileHandler({}));
          result.data = response.data;
          dispatch(authActions.setProfileHandler(response.data.data));
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          result.status = "error";
          result.msg = "Server error! Please try again later.";
        } else {
          console.log(error);
        }
      });
    return result;
  };
};

export const LoggedInHandler = (Token) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/customer/loggedin`,

      headers: {
        Authorization: "Bearer " + Token,
      },
    };

    await CustomerInstance(config)
      .then((response) => {
        if (response.data.status === "success") {
          result.status = "success";
          result.data.mobile = response.data.user.MobileNumber;
          result.data.id = response.data.user._id;
          dispatch(authActions.setLogDataHandler(response.data.user));
        }
      })
      .catch(function (error) {
        dispatch(LogOutHandle(Token));
        localStorage.removeItem("B2BAuthToken");
        console.log(error);
      });
    return result;
  };
};

export const LogOutHandle = (verfiyToken) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/customer/logout",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + verfiyToken,
      },
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          localStorage.removeItem("B2BAuthToken");
          dispatch(authActions.setProfileHandler());
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          result.status = "error";
          result.msg = "Server error! Please try again later.";
        } else {
          console.log(error);
        }
      });
    return result;
  };
};

export const SignUpHandler = (verifyToken, id, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/signup/${id}`,
      headers: {
        Authorization: "Bearer " + verifyToken,
      },
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          result.status = "error";
          result.msg = "Server error! Please try again later.";
        } else {
          console.log(error);
        }
      });
    return result;
  };
};

export const getProfileHandler = (verifyToken, id, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/getprofile/${id}`,
      headers: {
        Authorization: "Bearer " + verifyToken,
      },
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          result.status = "error";
          result.msg = "Server error! Please try again later.";
        } else {
          console.log(error);
        }
      });
    return result;
  };
};

export const updateProfileHandler = (verifyToken, id, data) => {
  return async (dispatch) => {
    let result = {
      status: "",
      msg: "",
      data: [],
    };

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `/api/b2bCustomer/update-profile/${id}`,
      headers: {
        Authorization: "Bearer " + verifyToken,
      },
      data: data,
    };

    await CustomerInstance(config)
      .then(function (response) {
        if (response.data.status === "success") {
          result.status = "success";
          result.data = response.data;
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          result.status = "error";
          result.msg = "Server error! Please try again later.";
        } else {
          console.log(error);
        }
      });
    return result;
  };
};
