import React from "react";
import classes from "./PaginationBox.module.css";
// import ReactPaginate from "react-paginate";
import { ReactComponent as ArrowRightSvg } from "../../Assessts/SvgComponents/arrowRightSvg.svg";
import { ReactComponent as ArrowLeft } from "../../Assessts/SvgComponents/ArrowLeft.svg";

function PaginationBox({
  limit,
  totalItems,
  currentPage,
  totalPages,
  onPageChange,
}) {
 
  const handlePageChange = (page) => {
    onPageChange(page);
  };

  const startItem = (currentPage - 1) * limit + 1;
  const endItem = Math.min(currentPage * limit, totalItems);

  return (
    <div className={classes.paginationContent}>
      <div className={classes.paginationInfo}>
        Showing {startItem} to {endItem} of {totalItems} results.
      </div>
      <div className={classes.paginationControls}>
        <button
          className={`${classes.prevButton} ${
            currentPage === 1 ? classes.opacSvg : ""
          }`}
          onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
        >
          <ArrowLeft />
        </button>
        <span className={classes.currentPage}>{currentPage}</span>

        <button
          className={`${classes.nextButton} ${
            currentPage === totalPages ? classes.opacSvg : ""
          }`}
          onClick={() =>
            currentPage < totalPages &&
            totalItems >= totalPages &&
            handlePageChange(currentPage + 1)
          }
        >
          <ArrowRightSvg />
        </button>
      </div>
    </div>
  );
}

export default PaginationBox;
